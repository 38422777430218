body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, p, ul, ol {
  margin-bottom: 0;
}

h2{
  font-size: 1.4rem;
  background-color:#F6EFE3;
  text-align: left;
  text-transform: uppercase;
  padding: .5rem;
  border-top-style: solid !important;
  border-top: 1.5px;
  border-top-color:rgba(108, 82, 35, 0.87);
  margin-top: 1rem !important;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.4rem;
  background-color: rgba(240, 229, 209, 0.15); /* was #F6EFE3; */
  text-align: left;
  text-transform: uppercase;
  padding: .5rem;
  border-top-style: solid !important;
  border-top: 1px;
  border-top-color:rgba(108, 82, 35, 0.75);
  margin-top: 1rem;
  margin-bottom: .5rem;
}

h3 .bi-info-circle {
  height: 1.4rem;
  width: 1.4rem;
  fill: #F57F17;
}

h4 {
  font-size: .75rem;
  line-height: 1.5rem;
}

a, .a_tooltip {
text-decoration: none !important;
font-style: italic;

}
p {
  text-align: justify;
}

.text-none {
  text-transform: none;
}

footer {
  background-color: #F6EFE3;
  border-top-style: solid !important;
  border-top: 2.5px;
  border-top-color:rgba(108, 82, 35, 1);

}

.fundname{
  color: #F57F17;
    font-size: 2rem !important;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 600;

}

#subHeader > div {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}
#subHeader > div > div:first-of-type > p:first-of-type {
  margin-top: 1.5rem;
  line-height: 1.5rem;
}
#subHeader > div > div:last-of-type > p:first-of-type {
  font-weight: 700;
}

.sectionHeader{
  background-color: #F2DFC4;
  border-top-style: solid !important;
  border-top: 2.5px;
  border-top-color:rgba(108, 82, 35, 1);
/*  
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
*/
}
.alert-light{
    color: rgba(245, 127, 3, 0.8);
    background-color: #fefefe;
    border-color: #fdfdfe;
  }

.info-icon { 
  color: #F57F17 !important;
  vertical-align: super;

 }

.uitleg{
    color: rgba(245, 127, 3, 0.8);
    background-color: #F57F17;
    border-color: #fdfdfe;
  }

.orange {
  color: #F57F17;
}

.blue, a {
  color: #18376D;
}


a:hover {
  color: #B8CCE4;
}

.bg-blue {
  background-color: #18376D;
}

.bg-beige {
  background-color: #F6EFE3;
}

.legenda > div {
  text-transform: uppercase;
  font-size: 12px;
}

.piechart-wrapper-large {
  position: relative;
  height: 250px;
  margin-top: .25rem;
}

.piechart-wrapper-small {
  position: relative;
  height: 200px;
  margin-top: .25rem;
}

@media(min-width: 992px) {
  .piechart-wrapper-large {
    height: 350px;
  }
}

.pie-circle {
  position: absolute;
  background-color: #fff;
}

.piechart-wrapper-large .pie-circle {
  width: 24%;
  height: 42%;
  top: 20%;
  left: 35%;
}

.piechart-wrapper-small .pie-circle {
  width: 35%;
  height: 28%;
  top: 29%;
  left: 27%;
}

@media(min-width: 992px) {
  .piechart-wrapper-large .pie-circle {
    width: 24%;
    top: 18%;
    left: 34%;
  }

  .piechart-wrapper-small .pie-circle {
    width: 32%;
    height: 35%;
    top: 23%;
    left: 28%;
  }
}

@media(min-width: 1140px) {
  .piechart-wrapper-large .pie-circle {
    width: 24%;
    left: 34%;
  }
}

@media(min-width: 1200px) {
  .piechart-wrapper-large .pie-circle {
    width: 40%;
    height: 39%;
    top: 21%;
    left: 22%;
  }

  .piechart-wrapper-small .pie-circle {
    width: 30%;
    height: 39%;
    left: 31%;
  }
}

@media(min-width: 1400px) {
  .piechart-wrapper-large .pie-circle {
    width: 40%;
    height: 45%;
    top: 20%;
    left: 25%;
  }

  .piechart-wrapper-small .pie-circle {
    width: 25%;
    left: 34%;
  }
}

@media (min-width: 1200px) {
  svg.speedometer {
    transform: scale(.9);
    margin-left: -1rem;
  }
}

@media (min-width: 1400px) {
  svg.speedometer {
    transform: inherit;
    margin-left: inherit;
  }
}

.speedometer-helper {
  position: absolute;
  top: 0;
}

.radar-wrapper {
  position: relative;
  height: 500px;
  margin-bottom: 1.5rem;
}

.radar-division-line-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(min-width: 1024px) {
  .radar-wrapper {
    height: 750px;
  }
}

.radar-division-line {
  height: 86%;
  margin-top: 16%;
  border-left: 4px dotted;
}

@media(min-width: 992px) {
  .radar-division-line {
    height: 95%;
    margin-top: 7%;
  }
}

@media(min-width: 1024px) {
  .radar-division-line {
    height: 92%;
    margin-top: 11%;
  }
}

@media(min-width: 1200px) {
  .radar-division-line {
    height: 100%;
    margin-top: 4%;
  }
}

.radar svg + div > div {
  transform: rotate(-12.86deg)
}

.print-visible {
  display: none;
}

@media print {
  .print-visible {
    display: initial;
  }

  .print-hidden {
    display: none !important;
  }

  .sticky-top {
    position: initial;
  }

  .piechart-wrapper-large {
    margin-bottom: 5rem;
  }
  .piechart-wrapper-large .legenda {
    margin-top: 3rem;
  }

  #keuzes {
    margin-top: 20rem;
  }

  #section4 {
    margin-top: 4rem;
  }

  #pensioenbeheer {
    margin-top: 20rem;
  }

  #pensioenbeheer-pbk-pf,
  #pensioenbeheer-pbk-alle {
    margin-top: 15rem;
  }

  #keuzes-pb-overdrachten-index {
    margin-top: 40rem !important;
  }

  #radar-extra-info {
    margin-top: 14rem;
  }
}